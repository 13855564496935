highlight {
 text: black;
 background-color: rgb(255, 247, 0);   
}

matches {
    text: black;
    background-color: #0f0;
}

.highlighMilUnitNumber {
    text-decoration-line: underline;
    border: 2px solid white;
    color: blue !important;
    cursor: pointer;
}

.highlighMilUnitNumber:hover {
    border-color: blue;    
}