.maps-container {
  .navi {
    z-index: 999;
    top: 5rem;
    left: 0.5rem;
    .osm-search-input {
      display: flex;
      align-items: center;
      label {
        top: -4px
      }
      div {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
  .maps-view-type-one-map,.maps-view-type-main-with-mini {
    .map-container-number-1 {
      .leaflet-left {
        top: 8rem;
      }
      .leaflet-top.leaflet-right {
        top: 16rem;
        left: 0;
        right: auto;
        .leaflet-control {
           margin-left: 10px;
           margin-right: 0;
        }
      }
    }
  }
  .maps-view-type-four-tiles {
    .map-container-number-1,
    .map-container-number-2 {
      .leaflet-top {
        top: 8rem;
      }
    }
  }
  .maps-view-type-four-tiles {
    .map-container-number-1 {
      order: 1;
    }
    .map-container-number-2 {
      order: 2;
    }
    .map-container-number-3 {
      order: 3;
    }
    .map-container-number-4 {
      order: 4;
    }
  }
}